import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { of, merge, tap, from } from 'rxjs';
import Axios from 'axios';

export const dayEpic = (action$, store) =>
  merge(
    action$
      .pipe(
        ofType('CHANGE_VIEW_DATE'),
        switchMap((action) => {
          return of({type: 'VIEW_DATE_CHANGED', payload: {calendar: store.value.data.data, newDate:action.payload}})
        })
      ),
  );