import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { of, merge, tap, from } from 'rxjs';
import Axios from 'axios';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

export const timetableEpic = (action$, store) =>
  merge(
    action$
      .pipe(
        ofType('INIT'),
        switchMap((action) => {
          console.log("store(from init):", store);
          const password = store.value.login.password;
          const username = store.value.login.username;
          const data = store.value.data.data;
          const actions = [];
          if(password && username){
            actions.push(of({type: 'LOAD_TIMETABLE_DATA', payload: {username:username, password:password}}));
          }
          if(data){
            actions.push(of({type: 'RWTH_TIMETABLE_DATA_LOADED', payload: {calendar: data}}));
          }
          if(actions. length > 0){
            return merge(...actions);
          }else{
            return of({type: 'NOACTION'})
          }
        })
      ),
    action$
      .pipe(
        ofType('LOAD_TIMETABLE_DATA'),
        switchMap((action) => {
          console.log('Try get data with data:', action.payload);
          //TODO: read this string out of the data on login. sem might change...
          const state = store.value;
          const password = state.login.password;
          const username = state.login.username;

          return from(Axios.post('api/timetable',
            new URLSearchParams({
            password : password,
            username : username,
          }).toString()))
        }),
        switchMap((result) => {
          console.log(result);
          if(result.status === 200){
            console.log('result.data.data ', result.data.data )
            if(result.data.loginFailed){
              // need new session.
              return of({type: 'SESSION_INVALID'});
            }else{
              // merge events
              let data = result.data;
              data = data.map((elm) => {
                return {...elm, id:elm.lv_nummer}
              })
              return merge(of({type: 'RWTH_TIMETABLE_DATA_LOADED', payload: {calendar: {timestamp: new Date(), data: data}}}),
                of({type: 'SESSION_VALID'}),
                of({type: "UPDATE_DETAIL"}));
            }
          } else {
            // something went wrong
            return of({type: 'HTTP_ERROR',
            payload: {message: 'error when loading table. Check Internet Connection.', 
              request: result}});
          }
        })
      ),
    action$
      .pipe(
        ofType("SHOW_DETAIL"),
        switchMap((action) => {
          return of({type: "UPDATE_DETAIL"});
        })
      ),
    action$
        .pipe(
          ofType("DETAIL_CHANGE"),
          switchMap((action) => {
            return of({type: "CUSTOM_DATA_CHANGED"});
          })
        ),
    action$
        .pipe(
          ofType("RWTH_TIMETABLE_DATA_LOADED", "CUSTOM_DATA_CHANGED"),
          switchMap((action) => {
            let payload = {calendar: store.value.data.data}
            let custom_data = store.value.data.custom_data
            if (action.type === "RWTH_TIMETABLE_DATA_LOADED"){
              // use incoming data
              payload = action.payload
            }
            payload.calendar.patched_data = payload.calendar.data.map((elm) => {
              // combine with custom data
              if (elm.id in custom_data){
                const patched_elm = {...elm , ...custom_data[elm.id]};
                return patched_elm;
              }
              return elm
            })
            return of({type: "TIMETABLE_DATA_LOADED", payload: payload});
          })
        ),
    action$
        .pipe(
          ofType("TIMETABLE_DATA_LOADED"),
          switchMap((action) => {
            return of({type: "UPDATE_DETAIL"});
          })
        ),
  );