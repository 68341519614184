export function getOtherDay(date, direction){
    const copy = new Date(date);
    copy.setHours(0,0,0,0);
    copy.setDate(copy.getDate() + direction);
    return copy;
}

export function getMonday(date, weekDirection){
    // get current monday:
    const day = date.getDay()
    const diff = date.getDate() - day + (day == 0 ? -6 : 1) + weekDirection * 7;
    return new Date(date.setDate(diff))
}

export function getTimeFromString(time){
    const split = time.split(':');
    return Number(split[0]) * 60 + Number(split[1]);
  }

export function formatDate(date) {
    let d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    return [day, month, year].join('.');
  }