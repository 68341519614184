
function loadPasswordFromLocalStorage() {
  const storage = localStorage.getItem('password')
  return (storage ? storage : '');
}

function loadUserFromLocalStorage() {
  const storage = localStorage.getItem('username')
  return (storage ? storage : '');
}
function loadAcceptFromLocalStorage() {
  const storage = localStorage.getItem('accept')
  return (storage ? storage : false);
}

export default (state={username: loadUserFromLocalStorage(), password: loadPasswordFromLocalStorage(), accept: loadAcceptFromLocalStorage(), showLogin: true}, action) => {
  switch(action.type){
    case 'SHOW_LOGIN':
      return {...state, showLogin:action.payload}
      break;
    case 'CHANGE_PASSWORD':
      return {...state, password: action.payload};
    case 'CHANGE_USERNAME':
      return {...state, username: action.payload};
    case 'CHANGE_ACCEPT':
      return {...state, accept: action.payload};
    case 'SESSION_INVALID':
      localStorage.setItem('session', null);
      return {...state, session: null};
    case 'NEW_SESSION':
      const password = action.payload.password;
      const username = action.payload.username;
      localStorage.setItem('password', password);
      localStorage.setItem('username', username);
      localStorage.setItem('accept', true);
      return {...state, password: password, username:username,  showLogin:false};
    default: 
      return state
  }
  return state;
}