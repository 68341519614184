import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { of, merge, tap, from } from 'rxjs';
import Axios from 'axios';
import { STATUS_STATES } from './../reducers/status';

export const statusEpic = (action$, store) =>
  merge(
    action$
      .pipe(
        ofType('SESSION_INVALID'),
        switchMap((action) => {
          if(store.value.status.dataState !== STATUS_STATES.DATA){
            return of({type: 'SHOW_LOGIN', payload: true})
          }
          return of({type: 'NOACTION'})
        })
      ),
  );