import { getOtherDay, getTimeFromString, formatDate } from "../helper/date";
console.log('otherday',getOtherDay);
/**
 * Wherever the both events take place to the same time.
 * 
 * @param {event} a 
 * @param {event} b 
 */
export function isOverlapping(a, b){
  const b_von = getTimeFromString(b.von);
  const b_bis = getTimeFromString(b.bis);
  const a_von = getTimeFromString(a.von);
  const a_bis = getTimeFromString(a.bis);
  return (a_bis > b_von && a_von < b_bis) || (a_von > b_von && a_bis < b_bis) || (b_von > a_von && b_bis < a_bis);
}

function getEventsForDay(data, dateTime) {
  // console.log(data)
  const date = formatDate(dateTime);
  const day = data.filter((d) => d.datum === date);
  day.sort((a, b) => (getTimeFromString(a.von) - getTimeFromString(b.von)));
  // calculate overlapping
  const dayWithOverlapping = day.map((event, index_a) => {
    // find overlapping
    const overlapping = day.reduce((acc, current, index_b) => {
      if (index_a !== index_b && isOverlapping(event, current)){
        if(index_b > index_a){
          acc.after += 1;
        } else {
          acc.before += 1;
        }
      }
      return acc;
    }, {before: 0, after:0})
    event.overlapping = overlapping;
    return event;
  })
  // console.log(dayWithOverlapping);
  return dayWithOverlapping;
}

export default (state={days: {}, currentDay: new Date(), viewDate: new Date().setHours(0,0,0,0)}, action) => {
  let days = state.days;
  switch(action.type){
    case 'INIT':
      break;
    
    case 'TIMETABLE_DATA_LOADED':
      days = {}
      // Do the same as date changed:
    // eslint-disable-next-line no-fallthrough
    case 'VIEW_DATE_CHANGED':
      //console.log('action.payload:', action.payload);
      const calendarData = action.payload.calendar.data;
      if(calendarData != "Service not available"){
        //console.log('data:');
        //console.log(calendarData);
        //console.log('newdate:', action.payload.newDate);
        const currentViewDay = (action.payload.newDate ? +action.payload.newDate : state.viewDate);
        //console.log('currentViewDay:', currentViewDay, new Date(currentViewDay));
        for(var i = -7; i < 7; i++){
          const date = getOtherDay(currentViewDay, i)
          //console.log('get data for:' + (+date) + " = " + date)
          if(days[(+date).toString()] == null){
            days[(+date).toString()] = getEventsForDay(calendarData, date);
          }
        }
        return {...state, days: {...days}};
      }
      break;
    case 'CURRENT_DATE_CHANGED':
      return {...state, currentDay: new Date()};
      break;
    case 'CHANGE_VIEW_DATE':
      return {...state, viewDate: action.payload};
    default: 
      return state
  }
  return state;
}