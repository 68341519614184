import React from 'react';
import Course, {getPositon} from './course';


const times = []
for(let i = 8; i < 22; i += 0.5){
  times.push(i);
}

function sameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
}

const Day = React.forwardRef((props, ref) => {
  let currentTime = null;
  if(sameDay(new Date(props.currentDay), new Date(props.date))){
    currentTime  = <div className="currentTime" style={{top:0, transform: 'translate(0px,' + getPositon(new Date(props.currentDay).getHours() * 60  + new Date(props.currentDay).getMinutes()) + 'px)' }}>
                      <div className="line"></div>
                      <div className="point"></div>
                    </div>
  }

  return (
    <div className="timetable">
      <div className="day" ref={ref}>
        <p className="number">{props.date && new Date(props.date).getDate()}</p>
        <p className="short">{props.date && new Date(props.date).toLocaleDateString(window.navigator.userLanguage || window.navigator.language, { weekday: 'short'})}</p>
      </div>
      <div className="hours">
        {
          times.map((time) => {
            const isFull = time == Math.floor(time);
            const showTime = (Math.floor(time) % 2 == 0)
            const classes = 'hour ' + (isFull ? 'fullHour' : 'halfHour') + ' ' + (showTime ? 'showTime' : 'hideTime');

            return <div key={time} className={classes}>
                      <div className="number">{((isFull ? time + ":00": Math.floor(time) + ":30"))}</div>
                      <div className="line"></div>
                    </div>}
          )
        }
      </div>
      {currentTime}
      <div className="courseContainer">
        {props.data && props.data.map((course) => <Course preview={false} showDetail={props.showDetail} key={[course.lv_nummer,course.datum,course.ort,course.von,course.bis].join('.')} data={course}></Course>)}
      </div>
    </div>
  );
});

export default Day;