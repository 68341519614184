import React, { Component } from 'react';
import { connect } from 'react-redux';
import Day from './day';
import Status from './status';
import TimeAgo from 'react-timeago';
// import {Motion, spring} from 'react-motion';
import { getOtherDay, getMonday } from '../helper/date';
import ReactSwipe from 'react-swipe';
import CourseDetail from "./course-detail"

function mapStateToProps(state) {
  return {
    timestamp: (state.data.data ? state.data.data.timestamp : null),
    currentDay: state.day.currentDay,
    viewDate: state.day.viewDate,
    days: state.day.days,
    isShowDetail: state.data.showDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeViewDate: (newViewDate) => dispatch({
      type : 'CHANGE_VIEW_DATE',
      payload: newViewDate
    }),

    showDetail: (id, date) => dispatch({
      type : "SHOW_DETAIL",
      payload: {id: id, date: date},
    }),

  }
}

function modulo(a,n){
  return ((a%n)+n)%n;
}

class Timetable extends Component {
  constructor(props){
    super(props)
    this.slideChanged = this.slideChanged.bind(this);
    this.swiped = this.swiped.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.reactSwipe = React.createRef();
    this.dayRefs = []
    for(let i = 0; i < 3; i++){
      this.dayRefs.push(React.createRef());
    }
    this.state = {viewDate: new Date(), width: window.innerWidth, height: window.innerHeight};
    this.pos = 1
    this.currentSlideElement = 1
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown.bind(this), false);
    window.addEventListener('resize', this.updateWindowDimensions);
    const ref = this.dayRefs[0].current;
    ref.scrollIntoView({block: "end", behavior: "smooth"});
    //this.reactSwipe.current.focus();
  }
  
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown.bind(this), false);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getDayOffset(state){
    if(state.width < 500){
      return 1;
    } else if (state.width < 1180){
      const daysToDisplay = (state.width < 900 ? 2 : 3)
      return daysToDisplay;
    } else {
      // display week
      // get prev monday
      return 7;
    }
  }

  handleKeyDown(event) {
    if (event.keyCode == 39 /*right arrow*/) {
      this.reactSwipe.current.next();
      // debugger
    }
    if (event.keyCode == 37 /*left arrow*/) {
      this.reactSwipe.current.prev();
    }
  }

  getCurrentDetail(){

  }

  slideChanged(index, elem){
    //debugger
    const daysMoved = (this.pos-1) * this.getDayOffset(this.state);
    console.log('slided:', (this.state.pos-1));
    this.props.changeViewDate(getOtherDay(this.props.viewDate, daysMoved));
    //this.setState({currentSlideElement: 1, pos: 1});
    this.pos = 1
    console.log('slide!:',index, elem);
  }

  swiped(index, elem){
    //debugger
    //this.setState((prevState) => {
    const modPos = modulo(this.pos , 3);
    const direction = ((modPos < index && !(modPos === 0 && index === 2))
                 || (modPos === 2 && index === 0) ? 1 : -1)

    console.log('direction:', direction, this.pos, modPos, index);
    this.pos = (this.pos + direction)
      //});
  }

  render() {
    const view_prev = getOtherDay(this.props.viewDate, -this.getDayOffset(this.state));
    const view_next = getOtherDay(this.props.viewDate, this.getDayOffset(this.state));
    const views = [view_prev, this.props.viewDate, view_next];
    var viewDays = [];
    for(const view of views){
      var days;
      const viewDate = view;
      if(this.state.width < 500){
        // display only today
        days = [viewDate];
      } else if (this.state.width < 1180){
        const daysToDisplay = (this.state.width < 900 ? 2 : 3)
        // display next 2 days
        days = Array.from({length: daysToDisplay}, (x, i) => getOtherDay(viewDate, i))
      } else {
        // display week
        // get prev monday
        const monday = getMonday(new Date(viewDate), 0);
        days = Array.from({length: 5}, (x, i) => getOtherDay(monday, i))
      }
      viewDays.push(days);
    }
    console.log('render',this.props.currentDay);
    console.log(days.map(day => (+day) + ' = ' + (day)));

    return (
      <div>
        <Status showHome={this.props.showHome}></Status>
        <div className="informationContainer">
          <div className="information">
            Last updated: <TimeAgo date={this.props.timestamp} minPeriod="5"/>
            {this.state.startX}
            {this.state.shiftX}
          </div>
        </div>

{/*      <Carousel key={JSON.stringify(viewDays)}
                selectedItem={this.state.pos}
                onSwipeEnd={this.slideChanged}
    showThumbs={false}> > */}
        {!this.props.isShowDetail &&<ReactSwipe ref={this.reactSwipe} key={JSON.stringify(viewDays)} swipeOptions={{callback:this.swiped, transitionEnd: this.slideChanged, startSlide: 1}}>
          {viewDays.map((days) => 
            <div className="dayContainer" key={days[0]}>
              {
                days.map((day, i) => <Day ref={this.dayRefs[i]} showDetail={(id) => this.props.showDetail(id, +day)} key={(+day)} currentDay={this.props.currentDay} date={+day} data={this.props.days[+day]} />)
              }
            </div>)
          }
        </ReactSwipe>}
      {this.props.isShowDetail && <CourseDetail />}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Timetable);
