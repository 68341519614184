import {formatDate} from "../helper/date"

function loadDataFromLocalStorage() {
  try{
    const obj = JSON.parse(localStorage.getItem('data'));
    return (obj.data.dozenten || obj.data.data === "Session is over" ? null : obj);
  } catch (e){
    localStorage.setItem('data', null);
  }
  return null;
}

function loadCustomFromLocalStorage() {
  try{
    const obj = JSON.parse(localStorage.getItem('custom_data'));
    return (obj !== null ? obj : {})
  } catch (e){
    localStorage.setItem('custom_data', {});
  }
  return {};
}
export default (state={data: loadDataFromLocalStorage(), custom_data:loadCustomFromLocalStorage(), showDetail:false, detail:null}, action) => {
  switch(action.type){
    case 'INIT':
      break;
    case 'TIMETABLE_DATA_LOADED':
      console.log('LOADED!');
      let data = action.payload.calendar;
      console.log(data)
      let save_data = {...data}
      delete save_data.custom_data
      localStorage.setItem('data', JSON.stringify(save_data));
      data.patched_data = data.patched_data.map((d)=> {
        let display = true;
        if ("display" in d){
          display = d.display;
          //console.log("display is drin", d.id);
        }
         return {...d, id:d.lv_nummer, display:display}
      })
      // replace data with the patched data
      const patched_data = data
      patched_data.data = patched_data.patched_data
      delete patched_data.patched_data
      return {...state, data: patched_data};
    case "SHOW_DETAIL":
      return {...state, showDetail:action.payload}
    case "SHOW_Home":
      return {...state, showDetail:null}
    case "UPDATE_DETAIL":
      // find the detatil we want to look at.
      if (state.showDetail != null){
        const datum = formatDate(state.showDetail.date);
        for(let elm of state.data.data){
          if(elm.id === state.showDetail.id){
            // TODO: maybe better use the full start date
            if(elm.datum === datum){
              return {...state, detail:{...elm}};
            }
          }
        }
        return {...state, detail:null};
      }
      return {...state};
    case "DETAIL_CHANGE":

      let new_custom_data = state.custom_data;
      new_custom_data[action.payload.id] = {
        ...new_custom_data[action.payload.id],
        ...action.payload
      }
      localStorage.setItem("custom_data", JSON.stringify(new_custom_data))
      return {...state, custom_data:new_custom_data}
    default: 
      return state
  }
  return state;
}
