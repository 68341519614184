import React, { Component } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-webpack-loader-syntax
import SettingsIcon from '-!svg-react-loader?name=SettingsIcon!./settings.svg';

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    showHome: () => dispatch({
      type : "SHOW_Home"
    })
  }
}

class Header extends Component {

  render() {
      return <div className="App-header" onClick={this.props.showHome}>
              <p className="Title">RWTH-Stundenplan</p>
              <div className="Settings"><SettingsIcon className="SettingsIcon"></SettingsIcon></div>
            </div>
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)