import React, { Component } from 'react';
import Course from './course';
import { connect } from 'react-redux';


function mapStateToProps(state) {
  return {
    detail: state.data.detail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handeViewCheckboxChange: (event, id) => dispatch({
      type : 'DETAIL_CHANGE',
      payload: {display: event.target.checked, id:id}
    }),
    handleNoteChange: (event, id) => dispatch({
      type : 'DETAIL_CHANGE',
      payload: {notes: event.target.value, id:id}
    }),
  }
}

class CourseDetail extends Component {

  render() {
    if (this.props.detail !== null) {
      let notes = this.props.detail.notes
      if (!notes){
        notes = "";
      }

      let settings_list = []
      Object.keys(this.props.detail).forEach((key) => {
        settings_list.push(<div key={"key_" + key} className="key element">{key}</div>)
        const value = this.props.detail[key];
        settings_list.push(<div key={"value_" + key} className="value element">{(typeof value === "string" ? value : JSON.stringify(value))}</div>)
      })
      console.log(settings_list)
      return <div className="courseDetail">
        <div className="coursePreview">
          <Course preview={true} data={this.props.detail} showDetail={() => null} />
        </div>
        <div className="section">
          <div className="title">
            Einstellungen
          </div>
          <div className="content">
            <div className="checkboxContainer">
              <input className="checkbox" type="checkbox" checked={this.props.detail.display} onChange={(event) => this.props.handeViewCheckboxChange(event, this.props.detail.id)} />
              <div>Im Stundenplan anzeigen</div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="title">
            Notizen
          </div>
          <div className="content">
            <input placeholder="Hier kannst du ein paar Notizen hinschreiben" name="password" onChange={(event) => this.props.handleNoteChange(event, this.props.detail.id)} value={this.props.detail.notes}/> {/* onChange={this.this.props.handePasswordChange} value={this.this.props.password}} /><br /> */}
          </div>
        </div>
        <div className="section">
          <div className="title">
            Infos
          </div>
          <div className="content">
            <div className="settingsview">
              {settings_list}
            </div>
          </div>
        </div>
      </div>
    } else {
      return <div className="courseDetail">Failed to load detail information</div>
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseDetail)