import React from 'react';
import { getTimeFromString } from '../helper/date';

// need to be the same in the scss file.
const timeOffset = 75;
const hourheight = 60;

export function getPositon(minutesIntoDay){
  const dayOffset = 8 * 60;
  const positon = (minutesIntoDay - dayOffset) * (60/hourheight);
  return positon;
}

function mapType(data){
  if(data.ereignis_typ && data.ereignis_typ === "exam date"){
    return "Prüfung";
  }
  switch (data.lv_art){
    case 'VU':
    case 'VO':
    case "LE":
    case "L":
      return "Vorlesung";
    case "F":
    case "TU":
      return "Tutorium";
    case "UE":
      return "Uebung";
    case "E":
      return "Einsicht";
    default:
        return data.lv_art;
  }
}

function getStyle(startTime, endTime, overlapping, preview){
  if(!preview) {
    const start = getPositon(startTime);
    const end = getPositon(endTime);
    // TODO: Fix to work with more than 1 overlapping course
    const width = 100 / (Math.min((overlapping.before + overlapping.after), 1) + 1)
    return {
      height: end - start,
      top: start,
      width: 'calc(' + width + '% - 16px)',
      left: (width * overlapping.before) + '%',
    }
  }
}

const Course = (props) => {
  return (
    <div className={"course " + (false ? 'canceled' : '') }
         style={getStyle(getTimeFromString(props.data.von), getTimeFromString(props.data.bis), props.data.overlapping, props.preview)}
         onClick={() => props.showDetail(props.data.id)}>
      {/* <div className="time">
        {props.data.von.substr(0,5)} - {props.data.bis.substr(0,5)}
      </div> */}
      <div className="firstLine">
        <div className="title">
          {props.data.titel}
        </div>
      </div>
        <div className="location">
          {props.data.ort}
        </div>
      <div className="group">
        {(props.data.lv_gruppe != "Standardgruppe" ? props.data.lv_gruppe : '')}
      </div>
      <div className="type">
        {mapType(props.data)}
      </div>
      <div className="anmerkung">
        {props.data.anmerkung}
      </div>
    </div>
  );
};

export default Course;